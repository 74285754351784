import { makeStyles } from '@material-ui/core/styles'
import { useRouter } from 'next/router'
import { Grid, Typography, Button } from '@material-ui/core'

import bg from '../assets/BG.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    background: `url(${bg}) no-repeat center`,
    backgroundSize: 'cover'
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    marginRight: 66
  },
  statusCode: {
    fontSize: 150,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textAlign: 'right'
  },
  error: {
    fontSize: 35,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'right',
    marginBottom: 24
  },
  message: {
    fontSize: 24,
    fontWeight: 500,
    color: '#fff',
    textAlign: 'right',
    marginBottom: 26
  },
  button: {
    marginLeft: 'auto'
  }
}))

export default function NotFound() {
  const classes = useStyles()
  const router = useRouter()
  return (
    <Grid
      className={classes.root}
      container
      justify="center"
      alignItems="center"
    >
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <div className={classes.content}>
          <Typography className={classes.statusCode} variant="h1">
            404
          </Typography>
          <Typography className={classes.error} variant="h2">
            Página não encontrada
          </Typography>
          <Typography className={classes.message} variant="body1">
            Desculpe, a página solicitada não pode <br /> ser encontrada
          </Typography>

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => router.push('/app')}
          >
            Voltar Para Home
          </Button>
        </div>
        <img
          src={require('../assets/error-404.svg')}
          alt="Cachorro batendo na porta"
          width={350}
        />
      </div>
    </Grid>
  )
}
